<template>
  <centered-layout>
    <card-with-logo>
      <template #title> {{ labels.LBL_FORGOT_PASSWORD }}</template>

      <template #subtitle>
        {{ labels.PH_FORGOT_PASSWORD }}
      </template>

      <el-form ref="forgotPasswordForm" :model="forgotPassword" :rules="rules">
        <el-form-item prop="email">
          <el-input
            v-model="forgotPassword.email"
            type="email"
            prefix-icon="el-icon-message"
            placeholder="Please input your email"
            @keyup.native.enter="onSubmit('forgotPasswordForm')"
          />
        </el-form-item>
      </el-form>

      <el-row type="flex" justify="space-between">
        <el-button type="text" @click="goBack"> Back </el-button>
        <el-button type="primary" @click="onSubmit('forgotPasswordForm')">
          Send
        </el-button>
      </el-row>
    </card-with-logo>
  </centered-layout>
</template>

<script>
  import { labels, rules } from "@/common";
  import CenteredLayout from "@/components/layouts/CenteredLayout";
  import CardWithLogo from "../components/CardWithLogo.vue";

  export default {
    name: "ForgotPassword",

    components: {
      CenteredLayout,
      CardWithLogo,
    },

    data() {
      return {
        labels,
        rules,
        forgotPassword: {
          email: "",
        },
      };
    },

    methods: {
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store
              .dispatch("auth/forgotPassword", this.forgotPassword)
              .then(() => {
                this.$refs[formName].resetFields();
              });
          }
        });
      },

      goBack() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style></style>
